import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

export const initFacilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		imagesAsBackgrounds: true,
		images: window.facCaroImgs,
		showDots: true,
		useChevrons: false
	});
};

export const initFacilityMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#facility_map'),
				locationElementSelector: '#facility_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/map_pin.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(14);
			});
		});
};

export const initFacilityMobile = () => {
	document.querySelector('#toggle_fac_info').addEventListener('click', () => {
		document.querySelector('#toggle_fac_info').switchAttribute('state', 'open', 'closed');
		document.querySelector('#facility_top').switchAttribute('state', 'open', 'closed');
	});
};