import './polyfills';
import { initMobileNav, initDoubleClick } from './header';
import { contactSubmit } from './contact.js';
import { multiMap } from './home.js';
import { initFacilityCaro, initFacilityMap, initFacilityMobile } from './facility';

initMobileNav();
initDoubleClick();

if(document.querySelector('#facility_caro')) {
	initFacilityCaro();
}

if(document.querySelector('#facility_map')) {
	initFacilityMap();
}

if(document.querySelector('#toggle_fac_info')) {
	initFacilityMobile();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'facility', 'email', 'message']
	});
}
if (document.getElementById('multi_map')) {
	multiMap();
}

const payBillOverlay = document.querySelector('#pay_bill_overlay');

payBillOverlay.addEventListener('click', e => {
	if(e.target.id === "pay_bill_overlay" || e.target.id === "close_bill_overlay") {
		payBillOverlay.removeAttribute('show');
	}
});

window.handlePayBill = e => {

	if(e.target.closest('a').getAttribute('href') === "#") {
		e.preventDefault();
		payBillOverlay.setAttribute('show', true);
	}
};